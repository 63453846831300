<template>
    <div style="margin-top:10px;" v-if="!dataLoading"> <!--margin:auto;width:50%;-->
      <div v-if="type=='certificate'">
        <div v-for="(row, index) in data" :key="index" style="page-break-inside: avoid;margin-top:5px;border-bottom-style: dashed; border-bottom-width: 1px;" class="width-custom height-custom" > <!--onlyPrint-->
              <div style="padding:1px 6px;">
                  <div class="header-custom">
                  <img src="media/logos/ems-logo-transparent.png" class="img" />
                  <span class="company-name-1">EMS Metrology</span></div>
                  <div class="label-custom">Certificate No: <span class="ml-1">{{row?.barcode}}</span></div>
                  <div class="label-custom">Serial No: <span class="ml-1">{{row?.serial_no}}</span></div>
                  <div class="label-custom">Calibration Date: <span class="ml-1">{{row?.calibration_date}}</span></div>
                  <div class="label-custom">Calibration Due Date: <span class="ml-1">{{row?.due_date_value}}</span></div>
                  <div class="label-custom">Contact Number: <span class="ml-1">{{row?.contact_no}}</span></div>
              </div>
          </div>
      </div>

      <!--border: 1px solid #c2c2c2;-->
      <div v-if="type=='tagging'">
        <div v-for="(row, index) in data" :key="index" style="page-break-inside: avoid;margin-top:5px;border-bottom-style: dashed; border-bottom-width: 1px;" class="width-custom height-custom"> <!--onlyPrint-->
            <div style="padding:1px 6px;" class="tagging-type">
                <div class="header-custom2 flex">
                    <div class="inner">
                        <span class="inner-image">
                            <img src="media/logos/ems-logo-transparent.png" class="img2" />
                            <span>EMS Metrology</span>
                           
                        </span>
                    </div>
                    <div class="inner">
                        <div class="label-custom">JO: <span class="job_number">{{row?.job_order_no}}</span></div>
                        <div class="label-custom">{{row?.date}}</div>
                        <!--{{row?.date}}-->
                    </div>
                </div>
                <div class="barcode-div">
                    <img src="media/logos/dummy-barcode.png" class="barcode" />
                </div>
                <div class="serial_number" v-if="row?.serial_no">*S/N: {{row?.serial_no}}*</div>
                <div class="flex bottom-layout">
                    <div class="inner">
                      {{row?.customer_name}}
                    </div>
                    <div class="inner">
                        QUOTE NO: {{row?.quotation_number}}
                    </div>
                </div>
                <div class="flex bottom-layout">
                    <div class="inner">
                        EQUIP TYPE: {{row?.equipement_type}}
                    </div>
                    <div class="inner">
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <div v-else>
      Please wait...
    </div>
</template>
<style>
.barcode-div{
    margin-top: -4px;
}
.job_number{
    font-size: 7px;
}
.bottom-layout{
    font-size:6px;
}
.flex{display: flex; flex-flow: wrap;}
.serial_number{
    font-weight: 700;
    font-size:8px;
    text-align: center;
    margin-right:10%;
}
.barcode{
    height: 12px;
    width: 85%;
}
.inner-image{
    display: inline-block;
    margin-top: 2px;
    font-size: 6px;
}
.inner {
  flex: 0 0 50%;
  width:50%;
  font-weight: 500;
  font-size: 4.6px;
}
.header-custom2{
    font-size:9px;
}
.img2{
    width: 22px;
    height: 22px;
    margin-top: -4px;
}


.company-name-1{
    font-size: 12px;
}
.img{
    width: 20px;
    height: 20px;
    margin-top: -3px;
}
.header-custom{
    font-weight: 600;
    font-size:9px;
}

.label-custom{
    font-weight: 500;
    font-size:7px;
    line-height: 0.8rem;
}
.tagging-type .label-custom{
    font-size:6px;
}
.width-custom{
    width:151.18110236px;
}
.height-custom{
    height:75.590551181px;
}
/* @media print {
  .noPrint {
      display:none;
  }
}
@media screen {
   .onlyPrint {
       display: none;
   }
} */

</style>
<script>
//import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";

import {
 // POST,
  GET,
//   PUT,
//   CLEAR_ERROR,
//   UPDATE_ERROR,
} from "@/core/services/store/request.module";

// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import Barcode from "@/view/pages/partials/Barcode.vue";
// import TableActivity from "@/view/pages/partials/Table-Activity.vue";
// import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
// import ListingTable from "@/view/pages/partials/Listing-Table.vue";
// import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
// import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
// import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
// import Dialog from "@/view/pages/partials/Dialog.vue";
// import { DOWNLOAD, POST, PATCH } from "@/core/services/store/request.module";
// import { saveAs } from "file-saver";
// import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
// import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import moment from "moment-timezone";
// import Datepicker from "@/view/pages/partials/Datepicker.vue";
// import JwtService from "@/core/services/jwt.service";
//import { cloneDeep, orderBy } from "lodash";

export default {
  mixins: [CommonMixin, ListingMixin],
  name: "tagging-list",
  data() {
    return {
      dataLoading: false,
      defaultColDefs:[],
      row:null,
      pageLoading: false,
      exportLoading: false,
      searching: false,
      pageModule: "tagging-listing",
      // routeAPI: "tagging",
      // routeName: "tagging",
      // routeDetailName: "tagging.detail",
      status: "all",
      file: "",
      dialog: false,
      barcodedialog: false,
      importDialog: false,
      statusList: [],
      customThead: [],
      defaultDate: moment().startOf("day").format("YYYY-MM-DD"),
      
      rowData_list: [
        {
          certificate: "S-235345",
          logo: "",
          serial_No: "23734545",
          equipment_type: "List1",
          job_order_no: "",
          due_date: "",
          createTime: "2 days ago",
        },
        {
          certificate: "S-235346",
          logo: "",
          serial_No: "23734545",
          equipment_type: "",
          job_order_no: "38534",
          due_date: "23/08/2023",
          createTime: "3 days ago",
        },
      ],
      categoryList: [],
      allowedSearchFields: [
        "barcode",
        "company_name",
        "person_display_name",
        "unit_no",
        "street_1",
        "street_2",
        "zip_code",
        "city_name",
        "state_name",
        "country_name",
        "create_time",
      ],
      alphabets: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ],
      moreActions: [
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      bulkActions: [
        {
          title: "Print Label",
          icon: "print",
          status: 1,
          action: "print_label",
        },
        {
          title: "Print Job Sheet",
          icon: "print",
          status: 1,
          action: "print",
        },
        {
          title: "Mark as Active",
          icon: "mdi-check-all",
          status: 1,
          action: "active",
        },
        {
          title: "Mark as In-Active",
          icon: "mdi-check-all",
          status: 0,
          action: "inactive",
        }
      ],
      type:null,
      data:[],
    };
  },
  components: {
    // PageHeaderCount,
    // draggable,
    // PageTips,
    // Barcode,
    // TableActivity,
    // ListingTemplate,
    // ListingFooter,
    // ListingTable,
    // ListingHeader,
    // ListingSearchCriteria,
    // Dialog,
    // Datepicker,
  },
  methods: {
    getData() {
        const _this = this;
        let query = _this.$route.query;
        var ids = query.ids;
        var type = query.type;
        _this.type = type;
        _this.dataLoading = true;
      return new Promise((resolve, reject) => {
        if (ids) {
          _this.$store
            .dispatch(GET, { url: "print-lable/"+type+"/bulk?ids="+ids })
            .then((response) => {
              resolve(response.data);
           //  console.log(response.data)
             this.data = response.data;
             setTimeout(function(){
              _this.dataLoading = false;
              window.print();
             }, 1000);
            
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve({});
        }
      });

    },
  },
  mounted() {
    this.getData();
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.status = _this.$route.query.status || _this.status;

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
  
};
</script>
